<template>
  <div class="noaccess">
    <p>Failed!!! Your payment was failed.</p>
    <p>Your account has been dctivated</p>
    <p>Thank You.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
export default defineComponent({
  setup () {
    const loading = ref<boolean>(false)
    return {
      loading
    }
  }
})
</script>

<style lang="scss">
.noaccess {
  background-color: #ffffff;
  height: 85vh;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
